@tailwind base;
@tailwind components;
@tailwind utilities;

/*
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@0,300;0,700;0,800;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;0,900;1,300&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:400,700,800,900,wght@0,300;0,600;0,700;0,800;0,900;'); */

@font-face {
    font-family: 'QB';
    src: url('/Quasunida-Bold.woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham-Book';
    src:    url('/Gotham-Book.woff') format('woff'),
            url('/Gotham-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }


body {
    font-family: 'QB', QB;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-bottom: env(safe-area-inset-bottom);
}
.gb {
    font-family: "Gotham-Book", sans-serif !important;
}
@layer utilities {
    @variants responsive {
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }

        .no-scrollbar {
            -ms-overflow-style: none;
            scrollbar-width: none;
        }

        @media only screen and (max-width: 640px) {
            .litepie-datepicker {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            .leading-relaxed {
                color: #cb2027
            }

            .away-cancel-picker {
                background-color: #cb2027;
                color: #ffffff;
            }
        }

        .shadowBox {
            -webkit-box-shadow: 0 0 10px 2px #e6e6e6;
            -moz-box-shadow: 0 0 10px 2px #e6e6e6;
            box-shadow: 0 0 10px 2px #e6e6e6;
        }

        button:disabled,
        button[disabled] {
            cursor: not-allowed;
        }

        input[type="checkbox"] {
            color: #cb2027;
        }

        .checkout-line-padding {
            padding-bottom: 1%;
        }

        .checkout-line-padding-s2 {
            padding-bottom: 1.5%;
        }
    }

}

.cursor-pointer {
    cursor: pointer;
}

#tabs {
    cursor: pointer;
}

.tab-list-move {
    transition: transform 1s;
}

/* Disables Blue Outline in Safari */
*:focus {
    outline: none;
}

button:focus {
    outline: none;
}

.loader {
    border-top-color: #cb2027;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

.w102 {
    width: 102%;
}

.em4 {
    font-size: 0.4em;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.h100vh {
    height: 100vh;
}

.h100dvh {
    min-height: 100dvh;
    max-height: 100dvh;
}

.h8 {
    height: 8%;
}

.h10 {
    height: 10%;
}

.h20 {
    height: 20%;
}

.h28 {
    height: 28%;
}

.h72 {
    height: 72%;
}

.h80 {
    height: 80%;
}

.h90 {
    height: 90%;
}

.h92 {
    height: 92%;
}

.lato {
    font-family: 'Lato', sans-serif;
}

.graysc {
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.t-rm4 {
    top: 0.4rem;
}

.t-rm6 {
    top: 0.6rem;
}
.rd-logo {
    width: 55%;
}
@media (min-width: 620px) {
    .rd-logo{
        width: 30%;
    }
}
@media (max-height: 804px) {
    .rd-logo{
        width: 40%;
    }
}

.rd-logo-main {
    width: 65%;
}
/* @media (min-width: 620px) {
    .rd-logo-main{
        width: 35%;
    }
}
@media (max-height: 804px) {
    .rd-logo-main{
        width: 45%;
    }
} */

.rd-caution-icon {
    right: 75px;
    top: 12px;
}

.fontWeight600 {
    font-weight: 600;
}
.no-solution-txt {
    margin-top: 2rem;
}
@media (max-height: 700px) {
    .no-solution-txt{
        margin-top: 1px;
    }
}
.rd-tab-check {
    margin-left: -2px
}

.rd-tab-check2 {
    top: 4px;
    right: 4px;
}

.rd-tab-name {
    font-family: QB;
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
}

.rd-tab-number {
    font-family: QB;
    font-size: 17px;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
}

.rd-input-bar {
    height: 54px;
    border-radius: 10px;
}
.rd-grey-border {
    border: 2px solid #CECECE;
    border-radius: 10px;
}
.rd-red-border {
    border: 2px solid #CB2027;
    border-radius: 10px;
}
.fontWeight700 {
    font-weight: 700;
}

.home-img {
    min-width: 221px;
    min-height: 249px;
    flex-shrink: 0;
    height: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.nav-padding {
    padding-left: 10%;
    padding-right: 10%;
}

#nav-indicator .nav-tab:not(:last-child):after {
    position: absolute;
    content: '';
    left: 90%;
    top: 40%;
    width: 50%;
    border-bottom: 2px solid #313131;
    ;
}

#nav-indicator .nav-return-tab:not(:last-child):after {
    position: absolute;
    content: '';
    left: 90%;
    top: 40%;
    width: 70%;
    border-bottom: 2px solid #313131;
    ;
}

.tooltiptext {
    height: 34px;
    width: 300px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    right: 20px;
    top: 36px;
}

.checkout-tooltiptext {
    height: 34px;
    width: 320px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    right: 0px;
    top: 0px;
}

.triangle-up {
    top: -10px;
    right: 5px;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #555;
}

.rd-cart-icon {
    left: -5px;
}

.rd-in-cart {
    top: 0;
    right: 0;
}

.step-indicator:before {
    content: "";
    display: block;
    width: 25%;
    height: 2px;
    background: #000;
    left: 0;
    top: 50%;
    position: absolute;
}

.step-indicator:after {
    content: "";
    display: block;
    width: 25%;
    height: 2px;
    background: #000;
    right: 0;
    top: 50%;
    position: absolute;
}


.check-circle {
    position: absolute;
    left: 30%;
    top: 15px;
}

.lock-icon {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 12px;
    z-index: 10;
    padding-right: 10px;
}

.rd-border-black {
    border-color: #313131;
}

.btn-line {
    font-family: QB;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.open_sans {
    font-family: QB;
}

.rd-back-btn {
    font-family: QB;
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
}
.rd-subtotal {
    font-family: QB;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
}
.rd-subtotal-amount {
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
}
.rd-subtotal-total {
    font-family: QB;
    font-size: 26px;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: 0em;

}
.rd-error-border {
    border: 3px solid #CB2027;
    border-radius: 10px;
}
.rd-title-1 {
    font-family: QB;
    font-size: 16px;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: 0em;
}
.rd-rental-name {
    font-family: QB;
    font-size: 16px;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: 0em;

}
.rd-bay-number {
    font-family: Lato;
    font-size: 24px;
    font-weight: 900;
    line-height: 29px;
    letter-spacing: 0em;
}
.rd-bay-number2 {
    font-family: Lato;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
}
/* Solutions */
.rd-solution-border {
    border: 2px solid #CB2027;
    border-radius: 20px;
}
.rd-solution-category-container {
    max-height: 94px;
}
.rd-solution-checkout-msg {
    font-family: QB;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;

}
.rd-solution-prompt-header {
    font-family: QB;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

}
.rd-solution-card {
    max-height: 260px;
}

.rd-qty-container {
    width: 55px;
    height: 33px;
}
.rd-qty-container2 {
    width: 55px;
    height: 22px;
}
.rd-qty {
    font-family: QB;
    font-size: 11px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
}

.rd-qty-amount {
    font-family: QB;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0em;
}

.rd-edit-remove {
    font-family: QB;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
}
.receipt-copy {
    font-size: 17px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
}

.rd-coupon-amount {
    color: #545454;
}

.rd-no-solution-img {
    /* background-image: url("../js/pages/assets/solution-purchase-banner.jpeg"); */
    background-image: url("../js/pages/assets/no-solutions.jpg");
    background-size: cover;
    background-position: center, center;
}
.rd-no-solution-font {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 43px;
    text-transform: uppercase;
}
